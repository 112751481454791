const IS_PLAYING_CLASS = 'is-playing';

export function Video(element: HTMLElement) {
	let videoTag = element.querySelector('video');
	let embedCode = element.dataset.videoEmbedCode;

	if (videoTag) {
		videoTag.addEventListener('ended', onVideoEnd);
		videoTag.addEventListener('pause', onVideoPause);
		videoTag.addEventListener('play', onVideoPlay);

		element.addEventListener('click', onVideoClick);
	}

	if (embedCode) {
		createObserver();
	}

	function onVideoClick(event: MouseEvent) {
		if (videoTag) {
			event.preventDefault();
			videoTag.paused ? videoTag.play() : videoTag.pause();
		}
	}

	function onVideoPlay() {
		element.classList.add(IS_PLAYING_CLASS);
	}

	function onVideoPause() {
		element.classList.remove(IS_PLAYING_CLASS);
	}

	function onVideoEnd() {
		element.classList.remove(IS_PLAYING_CLASS);
	}

	function createObserver() {
		let observer = new IntersectionObserver((entries) => {
			entries.forEach(loadEmbed);
		});

		observer.observe(element);

		function loadEmbed(entry: IntersectionObserverEntry) {
			if (!entry.isIntersecting) {
				return;
			}

			let embedTarget = element.querySelector('[data-video-target="embed"]');

			if (!embedTarget) {
				return;
			}

			observer.disconnect();
			embedTarget.innerHTML = embedCode || '';
		}
	}
}
