const defaults = {
	root: null,
	rootMargin: '20%',
	threshold: 0,
	srcAttr: 'data-src',
	srcsetAttr: 'data-srcset',
	selector: '[data-src], [data-srcset]',
	onLoad: (element: HTMLElement) => element.classList.add('has-loaded'),
	onError: (element: HTMLElement) => element.classList.add('has-errored'),
};

export function LazyLoader(options) {
	const config = { ...defaults, ...options };

	const io = new IntersectionObserver(onIntersection, {
		root: config.root,
		rootMargin: config.rootMargin,
		threshold: config.threshold,
	});

	const mutationObserver = new MutationObserver(onMutate);

	function onMutate() {
		getElements().forEach((elem) => io.observe(elem));
	}

	mutationObserver.observe(document, {
		childList: true,
		subtree: true,
		attributes: true,
	});

	let elements: HTMLElement[] = [];

	function getElements() {
		return Array.from(document.querySelectorAll(config.selector));
	}

	function update() {
		elements = getElements();
		elements.forEach((elem) => io.observe(elem));
	}

	function onIntersection(entries: IntersectionObserverEntry[]) {
		entries
			.filter((entry) => entry.isIntersecting)
			.map((entry) => load(entry.target));
	}

	function setAttributes(elem) {
		const { srcAttr, srcsetAttr } = config;

		const src = elem.getAttribute(srcAttr);
		const srcset = elem.getAttribute(srcsetAttr);

		if (srcset) {
			elem.srcset = srcset;
			elem.removeAttribute(srcsetAttr);
		}

		if (src) {
			elem.src = src;
			elem.removeAttribute(srcAttr);
		}
	}

	function load(element: HTMLElement) {
		const index = elements.indexOf(element);

		element.onload = () => config.onLoad(element);
		element.onerror = () => config.onError(element);

		setAttributes(element);

		io.unobserve(element);
		elements.splice(index, 1);
	}

	return { update };
}
