import { Accordion } from './modules/accordion';
import { Address } from './modules/commerce/address';
import { Alert } from './modules/alert';
import { App } from './application';
import { AutoSubmitForm } from './modules/auto-submit-form';
import { CookieConsent } from './modules/cookie-consent';
import { Disclosure } from './modules/disclosure';
import { Dropdown } from './modules/dropdown';
import { FauxLink } from './modules/faux-link';
import { FetchLink } from './modules/fetch-link';
import { Header } from './modules/header';
import { LazyLoader } from './modules/lazy-loader';
import { MegaNav } from './modules/mega-nav';
import { NavigationDrawer } from './modules/navigation-drawer';
import { PhoneField } from './modules/phone-field';
import { ProductFinder } from './modules/product-finder';
import { ProductGallery } from './modules/product-gallery';
import { ProductVariantSelector } from './modules/product-variant-selector';
import { ShowPasswordInput } from './modules/show-password-input';
import { Slider } from './modules/slider';
import { SpinButton } from './modules/spin-button';
import { StickyNavbar } from './modules/sticky-navbar';
import { StripeForm } from './modules/commerce/stripe/stripe';
import { Video } from './modules/video';
import { VideoPlayButton } from './modules/video-control';

let app = App();

app.start();
app.register('accordion', Accordion);
app.register('alert', Alert);
app.register('auto-submit-form', AutoSubmitForm);
app.register('commerce-address', Address);
app.register('disclosure', Disclosure);
app.register('dropdown', Dropdown);
app.register('faux-link', FauxLink);
app.register('header', Header);
app.register('product-gallery', ProductGallery);
app.register('product-variant-selector', ProductVariantSelector);
app.register('show-password-input', ShowPasswordInput);
app.register('slider', Slider);
app.register('spin-button', SpinButton);
app.register('video', Video);
app.register('product-finder', ProductFinder);
app.register('fetch-link', FetchLink);
app.register('phone-field', PhoneField);
app.register('video-play-button', VideoPlayButton);
app.register('stripe-form', StripeForm);

CookieConsent();
MegaNav();
NavigationDrawer();
StickyNavbar();

let imageLoader = LazyLoader({
	rootMargin: '30%',
	selector: '[data-src], [data-srcset]',
	srcAttr: 'data-src',
	srcsetAttr: 'data-srcset',
});

imageLoader.update();
