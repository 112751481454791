export function getInputValue(element: Element);
export function getInputValue(name: string, form: HTMLFormElement);
export function getInputValue(
	name: Element | string,
	form: HTMLFormElement | undefined = undefined,
) {
	let value;
	// @ts-ignore https://github.com/microsoft/TypeScript/issues/19437
	let field;

	if (name instanceof HTMLElement) {
		field = name;
	} else {
		field = form.elements[name];
	}

	if (field === undefined) {
		return value;
	}

	if (isRadioNodeListLike(field)) {
		let elements = Array.from(field) as HTMLInputElement[];
		let isRadioGroup = elements.every((el) => el.type === 'radio');
		let values = elements.filter((el) => el.checked).map((el) => el.value);

		if (!isRadioGroup) {
			value = values;
		} else {
			value = values.length ? values[0] : '';
		}

		return value;
	}

	if (field instanceof HTMLSelectElement) {
		let options = Array.from(field.options);
		let isSingleSelect = field.type === 'select-one';

		if (isSingleSelect) {
			value = options[field.selectedIndex].value;
		} else {
			value = options.filter((opt) => opt.selected).map((opt) => opt.value);
		}

		return value;
	}

	if (field instanceof HTMLInputElement) {
		if (field.type === 'checkbox') {
			value = field.checked ? field.value : '';
		} else {
			value = field.value;
		}

		return value;
	}

	return value;
}

export function getInputValues(names: string[], form: HTMLFormElement) {
	let valuesByName: { [name: string]: string | string[] | undefined } = {};

	names.forEach(function (name) {
		valuesByName[name] = getInputValue(name, form);
	});

	return valuesByName;
}

export function getInputNames(elements: HTMLElement[]) {
	let names = elements
		.filter((el) => el.hasAttribute('name'))
		.map((el) => el.name);

	return Array.from(new Set(names));
}

/**
 * Check a value to see if its an object representing a collection of checkbox or
 * radio elements. Most browsers correctly report RadioNodeList for both however
 * IE returns a HTMLCollection despite supporting RadioNodeList.
 */
function isRadioNodeListLike(element: any) {
	return (
		element instanceof HTMLCollection ||
		(typeof RadioNodeList !== 'undefined' && element instanceof RadioNodeList)
	);
}
