import { createElement } from '../utils/dom';
import { postForm } from '../utils/fetch';

export function ProductFinder(element: HTMLElement) {
	// element.addEventListener('submit', submit);
	element.addEventListener('change', onChange);

	disableButtons();

	function getButtons() {
		return Array.from(element.querySelectorAll('button[type="submit"]'));
	}

	function getLinks() {
		return Array.from(element.querySelectorAll('a'));
	}

	function enableButtons() {
		getButtons().forEach((button) => toggleDisabled(button, false));
	}

	function disableButtons() {
		getButtons().forEach((button) => toggleDisabled(button, true));
	}

	function toggleDisabled(element: Element, toggle: boolean) {
		element.classList.toggle('is-disabled', toggle);
	}

	function onChange() {
		enableButtons();
	}

	async function submit(event: SubmitEvent) {
		event.preventDefault();

		getButtons().forEach((button) => {
			let label = createElement('span');

			label.style.visibility = 'hidden';
			label.textContent = button.textContent;

			button.innerHTML = '';
			button.append(label);
			button.classList.add('is-loading');
		});

		let form = element.querySelector('form');
		let response = await postForm(<HTMLFormElement>form);

		handleResponse(response);
	}

	async function handleResponse(response: Response | null) {
		let text = await response.text();
		let temp = document.createElement('div');
		temp.innerHTML = text;

		document.getElementById('product-finder').innerHTML =
			temp.querySelector('#product-finder').innerHTML;

		if (element.querySelectorAll('input:checked').length === 0) {
			disableButtons();
		}
	}
}
