export function Header(element: HTMLElement) {
	if (!(element instanceof HTMLElement)) {
		return;
	}

	let searchOpen = false;
	let searchTrigger: HTMLElement | null;
	let searchElement = element.querySelector('.js-header-search') as HTMLElement;
	let searchInput = searchElement?.querySelector('input') as HTMLInputElement;

	let searchButtons = Array.from(
		element.querySelectorAll('.js-header-search-button'),
	);

	searchButtons.forEach((element) => {
		element.addEventListener('click', function (event) {
			event.preventDefault();

			searchTrigger = element as HTMLElement;
			searchOpen ? hideSearch() : showSearch();
		});
	});

	document.addEventListener('keydown', function (event) {
		if (event.key !== 'Escape') {
			return;
		}

		hideSearch();
		searchTrigger?.focus();
	});

	document.addEventListener('click', function (event) {
		let target = event.target as HTMLElement;

		if (searchTrigger?.contains(target) || searchElement.contains(target)) {
			return;
		}

		hideSearch();
	});

	function showSearch() {
		if (!searchElement) {
			return;
		}

		let onPageSearchInput = document.getElementById('search');

		if (onPageSearchInput) {
			onPageSearchInput.focus();
			return;
		}

		searchOpen = true;
		searchElement.style.display = 'block';
		searchInput.focus();
	}

	function hideSearch() {
		if (!searchElement) {
			return;
		}

		searchOpen = false;
		searchElement.style.display = 'none';
	}
}
