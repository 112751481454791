const hoverClass = 'has-hover';

export function FauxLink(element: HTMLElement) {
	if (!(element instanceof HTMLElement)) {
		return;
	}

	element.addEventListener('mouseout', () => toggleHover(element, false));
	element.addEventListener('mouseenter', () => toggleHover(element, true));
}

function toggleHover(element: HTMLElement, force: boolean) {
	if (!element) {
		return;
	}

	element.classList.toggle(hoverClass, force);
}
