import { accordion } from '@rynpsc/accordion';

export function Accordion(element: HTMLElement) {
	let { id } = element;

	let instance = accordion(id, {
		animate: true,
		multiselect: true,
		headerButtonClass: 'c-accordion__button',
		initialisedClass: 'initiated',
		activePanelClass: 'is-open',
		activeHeaderClass: 'is-open',
	});

	if (instance) {
		instance.init();
	}
}
