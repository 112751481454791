import { dialog, domapi } from '@rynpsc/dialog';

interface NavigationDrawerItem {
	open: () => any;
	close: () => any;
}

domapi.mount();

export function NavigationDrawer() {
	let element = document.querySelector('.js-navigation-drawer');

	if (!(element instanceof HTMLElement)) {
		return;
	}

	let activePane: NavigationDrawerItem | null;
	let contents = element.querySelector('.js-drawer-contents');
	let firstPane = element.querySelector('.js-navigation-drawer-first');
	let backButton = element.querySelector('.js-navigation-drawer-back');

	let dialogInstance = dialog(element.id);

	if (!dialogInstance || !contents) {
		return;
	}

	dialogInstance.create();
	dialogInstance.on('open', handleDrawerOpen);
	dialogInstance.on('close', handleDrawerClose);

	Array.from(element.querySelectorAll('.js-navigation-drawer-item')).map(
		(element) => NavigationDrawerItem(element as HTMLElement),
	);

	if (backButton) {
		backButton.addEventListener('click', handleBackButtonClick);
	}

	dialogInstance.element.addEventListener('click', handleDrawerClick);

	function handleBackButtonClick(event: Event) {
		if (activePane) {
			activePane.close();
		}

		event.preventDefault();
	}

	function handleDrawerClick(event: MouseEvent) {
		if (contents?.contains(event.target as HTMLElement)) {
			return;
		}

		activePane?.close();
		dialogInstance?.close();
	}

	function handleDrawerOpen() {
		document.body.style.overflow = 'hidden';
	}

	function handleDrawerClose() {
		document.body.style.overflow = '';

		if (!activePane) {
			return;
		}

		activePane.close();
	}

	function NavigationDrawerItem(linkElement: HTMLElement) {
		let targetId = linkElement.dataset.target;
		let navElement = targetId ? document.getElementById(targetId) : null;

		let item: NavigationDrawerItem = { open, close };

		if (navElement) {
			linkElement.addEventListener('click', (event) => {
				open();
				event.preventDefault();
			});
		}

		function open() {
			navElement?.classList.add('is-open');

			if (backButton) {
				backButton.setAttribute('tabindex', '0');
				backButton.setAttribute('role', 'button');
				backButton.textContent = 'Back to main menu';
			}

			(firstPane as HTMLElement).style.display = 'none';

			activePane = item;
		}

		function close() {
			if (navElement) {
				navElement.classList.remove('is-open');
			}

			if (backButton) {
				backButton.removeAttribute('role');
				backButton.removeAttribute('tabindex');
				backButton.textContent = 'Main menu';
			}

			(firstPane as HTMLElement).style.display = '';

			activePane = null;
			linkElement.focus();
		}

		return item;
	}
}
