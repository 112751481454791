import { StripeElementStyle } from '@stripe/stripe-js/types/stripe-js/elements/base';

export let style: StripeElementStyle = {
	base: {
		color: '#4e4e4e',
		fontSize: '15px',
		fontFamily:
			'"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", "Arial", sans-serif',
		lineHeight: '1.4',
		'::placeholder': {
			color: '#a8acc0',
		},
	},
	invalid: {
		color: '#8c0000',
	},
};
