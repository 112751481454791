import { clamp } from '../utils/number';
import { wrap } from '../utils/dom';
import plusIcon from '/src/icons/plus.svg?raw';
import minusIcon from '/src/icons/minus.svg?raw';

export let instances = {};

export function SpinButton(input: HTMLInputElement) {
	if (!(input instanceof HTMLInputElement)) {
		return undefined;
	}

	let timer: number;
	let currentValue = Number(input.value);
	let minValue = input.dataset.min ? Number(input.dataset.min) : null;
	let maxValue = input.dataset.max ? Number(input.dataset.max) : null;

	let wrapper = document.createElement('div');
	let incrementButton = document.createElement('button');
	let decrementButton = document.createElement('button');

	input.setAttribute('pattern', '[0-9]*');
	input.setAttribute('inputmode', 'numeric');
	input.setAttribute('autocomplete', 'off');
	input.setAttribute('role', 'spinbutton');
	input.addEventListener('keydown', handleKeydown);
	input.addEventListener('blur', () => setValue(input.value));

	incrementButton.setAttribute('type', 'button');
	decrementButton.setAttribute('type', 'button');
	incrementButton.setAttribute('tabindex', '-1');
	decrementButton.setAttribute('tabindex', '-1');
	incrementButton.setAttribute('aria-label', 'Increase');
	decrementButton.setAttribute('aria-label', 'Decrease');
	incrementButton.innerHTML = plusIcon;
	decrementButton.innerHTML = minusIcon;

	wrapper.className = 'c-spin-button';
	incrementButton.className = 'c-spin-button__increment';
	decrementButton.className = 'c-spin-button__decrement';

	wrap(input, wrapper);
	wrapper.append(incrementButton);
	wrapper.prepend(decrementButton);

	incrementButton.addEventListener('mouseup', clearTimer);
	incrementButton.addEventListener('mousedown', () => autoChange(1));
	incrementButton.addEventListener('mouseout', clearTimer);
	decrementButton.addEventListener('mouseup', clearTimer);
	decrementButton.addEventListener('mousedown', () => autoChange(-1));
	decrementButton.addEventListener('mouseout', clearTimer);

	setMin(minValue);
	setMax(maxValue);
	setValue(currentValue);

	input.addEventListener('input', () => {
		// User might be trying to clear input so lets stop interfering
		if (input.value === '') {
			return undefined;
		}

		setValue(input.value);
	});

	function autoChange(amount: number) {
		setValue(currentValue + amount);

		timer = setTimeout(() => {
			clearTimeout(timer);
			timer = setInterval(() => setValue(currentValue + amount), 100);
		}, 300);
	}

	function clearTimer() {
		clearTimeout(timer);
		clearInterval(timer);
	}

	function setMin(value: number) {
		if (!value && value != 0) {
			minValue = null;
			input.removeAttribute('aria-valuemin');
		} else {
			minValue = Number(value);
			input.setAttribute('aria-valuemin', String(value));
		}

		setValue(currentValue);
	}

	function setMax(value: number) {
		if (!value && value != 0) {
			maxValue = null;
			input.removeAttribute('aria-valuemax');
		} else {
			maxValue = Number(value);
			input.setAttribute('aria-valuemax', String(value));
		}

		setValue(currentValue);
	}

	function setValue(value: number | string) {
		if (typeof value === 'string') {
			value = value.replace(/\D/g, '');
		}

		if (value === '') {
			value = minValue !== null ? minValue : 0;
		}

		let clampedValue = clamp(Number(value), minValue, maxValue);

		decrementButton.classList.toggle('is-disabled', clampedValue === minValue);
		incrementButton.classList.toggle('is-disabled', clampedValue === maxValue);

		decrementButton.setAttribute(
			'aria-disabled',
			String(clampedValue === minValue),
		);
		incrementButton.setAttribute(
			'aria-disabled',
			String(clampedValue === maxValue),
		);

		currentValue = clampedValue;
		input.value = String(clampedValue);
		input.setAttribute('aria-valuenow', String(clampedValue));
		input.dispatchEvent(new Event('spinbutton:change'));
	}

	function increment(step = 1) {
		setValue(currentValue + step);
	}

	function decrement(step = 1) {
		setValue(currentValue - step);
	}

	function handleKeydown(event) {
		if (event.key === 'ArrowUp') {
			increment();
			event.preventDefault();
		}

		if (event.key === 'ArrowDown') {
			decrement();
			event.preventDefault();
		}

		if (event.key === 'End') {
			event.preventDefault();
			setValue(Number(input.dataset.max));
		}

		if (event.key === 'Home') {
			event.preventDefault();
			setValue(Number(input.dataset.min));
		}
	}

	instances[input.id] = {
		setMin,
		setMax,
		setValue,
	};

	return instances[input.id];
}
