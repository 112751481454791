import Cookies, { CookieAttributes } from 'js-cookie';
import { all } from '../utils/dom';
import { isSubmitButton } from '../utils/types';
import { postForm } from '../utils/fetch';

export function Alert(element: HTMLElement) {
	let cookieName = element.dataset.id;

	let controls = all(element, '.js-alert-dismiss');

	controls.forEach((elem) => {
		(<HTMLElement>elem).addEventListener('click', controlClick);
	});

	let cookieOptions: CookieAttributes = {
		sameSite: 'None',
		secure: true,
	};

	if (element.dataset.expiry) {
		cookieOptions.expires = new Date(element.dataset.expiry);
	}

	function controlClick(event: MouseEvent) {
		let currentTarget = event.currentTarget as HTMLElement;

		event.preventDefault();

		if (isSubmitButton(currentTarget)) {
			let form = (currentTarget as HTMLButtonElement | HTMLInputElement).form;

			if (form) {
				postForm(form);
			}
		}

		if (cookieName) {
			Cookies.set(cookieName, '1', cookieOptions);
		}

		(element as HTMLElement).remove();
	}
}
