import { all, one } from '../utils/dom';
import { modulo } from '../utils/number';

let openClass = 'is-open';
let readyClass = 'is-ready';
let selectedClass = 'is-selected';

export function Dropdown(element: HTMLElement) {
	let isOpen = false;
	let startIndex = 0;
	let currentIndex = 0;

	let button = element.firstElementChild;
	let options = all(element, 'ul a, ul button');

	if (!(button instanceof HTMLElement) || options.length === 0) {
		return;
	}

	element.classList.add(readyClass);
	element.addEventListener('keydown', elementKeydown);

	all(element, 'ul').forEach((e) => e.setAttribute('role', 'menu'));
	all(element, 'li').forEach((e) => e.setAttribute('role', 'none'));

	button.setAttribute('tabindex', '0');
	button.addEventListener('click', buttonClick);
	element.addEventListener('focusout', elementFocusout);

	options.forEach((option) => {
		option.setAttribute('tabindex', '-1');
		option.setAttribute('role', 'menuitem');
		option.addEventListener('click', optionClick);
		option.addEventListener('mouseover', optionMouseOver);
	});

	startIndex = options.findIndex((e) => e.classList.contains(selectedClass));

	if (startIndex === -1) {
		startIndex = 0;
	}

	function open() {
		isOpen = true;
		element.classList.add(openClass);
		document.addEventListener('click', documentClick);
		document.addEventListener('keydown', documentKeydown);

		focus(startIndex);
		position();
	}

	function close() {
		isOpen = false;
		element.classList.remove(openClass);
		document.removeEventListener('click', documentClick);
		document.removeEventListener('keydown', documentKeydown);

		focus(startIndex);
	}

	function position() {
		let ul = one(element, 'ul');

		if (!(ul instanceof HTMLUListElement)) {
			return;
		}

		ul.removeAttribute('style');
		let rect = ul?.getBoundingClientRect();
		let buttonRect = button.getBoundingClientRect();

		let diff = Math.max(rect.right - document.body.clientWidth, 0);
		ul.style.left = `${-diff - 7}px`;

		let arrowLeft = 7;

		if (buttonRect.width <= 50) {
			arrowLeft = diff + buttonRect.width / 2;
		}

		element.style.setProperty('--arrow-left', `${arrowLeft}px`);
	}

	function documentClick(event: MouseEvent) {
		let target = event.target as HTMLElement;

		if (element.contains(target)) {
			return;
		}

		close();
	}

	function documentKeydown(event: KeyboardEvent) {
		if (!['Escape'].includes(event.code)) {
			return;
		}

		close();
	}

	function buttonClick(event: MouseEvent) {
		event.preventDefault();

		isOpen ? close() : open();
	}

	function elementFocusout(event: FocusEvent) {
		if (element.contains(event.target as HTMLElement)) {
			return;
		}

		close();
	}

	function elementKeydown(event: KeyboardEvent) {
		if (!['ArrowDown', 'ArrowUp', 'Enter', 'Space'].includes(event.code)) {
			return;
		}

		if (!isOpen) {
			open();
			event.preventDefault();
			return;
		}

		if (event.code === 'ArrowDown') {
			focusRelative(1);
			event.preventDefault();
		} else if (event.code === 'ArrowUp') {
			focusRelative(-1);
			event.preventDefault();
		} else if (event.code === 'Enter' || event.code === 'Space') {
			select(currentIndex);
			event.preventDefault();
		}
	}

	function optionClick(event: MouseEvent) {
		select(options.indexOf(event.currentTarget));
	}

	function optionMouseOver(event: MouseEvent) {
		focus(event.target);
	}

	function focus(value: number | HTMLElement) {
		let option;

		options[currentIndex].classList.remove(selectedClass);

		if (typeof value !== 'number') {
			option = value;
			currentIndex = options.indexOf(value);
		} else {
			option = options[value];
			currentIndex = value;
		}

		option.classList.add(selectedClass);
	}

	function focusRelative(number: number) {
		focus(modulo(currentIndex + number, options.length));
	}

	function select(index: number) {
		let option = options[index];

		if (!option) {
			return;
		}

		if (option.matches('a, button')) {
			option.click();
		} else {
			option.dispatchEvent(new Event('click'));
		}

		close();

		let textElement = button.querySelector('.js-dropdown-btn-text');

		if (textElement) {
			textElement.textContent = option.textContent;
		}
	}

	return {
		open,
		close,
	};
}
