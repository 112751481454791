import playSvg from '@src/icons/play.svg?raw';
import pauseSvg from '@src/icons/pause.svg?raw';

const PAUSED_CLASS = 'is-paused';
const PLAYING_CLASS = 'is-playing';

export function VideoPlayButton(element: HTMLElement) {
	let video = document.getElementById(
		element.dataset.controls,
	) as HTMLVideoElement | null;

	if (!(video instanceof HTMLVideoElement)) {
		return;
	}

	element.style.display = 'block';
	element.addEventListener('click', togglePlay);
	video.addEventListener('play', checkStatus);
	video.addEventListener('pause', checkStatus);

	let reducedMotionNoPref = window.matchMedia(
		'(prefers-reduced-motion: no-preference)',
	);

	if (reducedMotionNoPref.matches) {
		play();
	}

	function play() {
		try {
			video?.play().then(() => checkStatus());
		} catch (error) {
			checkStatus();
		}
	}

	reducedMotionNoPref.addEventListener('change', () => {
		reducedMotionNoPref.matches ? play() : video?.pause();
	});

	function checkStatus() {
		let playing = isPlaying();

		element.classList.toggle(PAUSED_CLASS, !playing);
		element.classList.toggle(PLAYING_CLASS, playing);
		element.innerHTML = playing ? pauseSvg : playSvg;
	}

	function togglePlay() {
		!isPlaying() ? play() : video?.pause();
	}

	function isPlaying() {
		// It is advised to check if video?.currentTime > 0 however that returns false
		// if the video has just started which in our case means that when we trigger
		// .play and then call checkStatus we get false.

		return !video?.ended && !video?.paused && video?.readyState > 2;
	}
}
