import { fetchHtml } from '../utils/fetch';
import { createElement } from '../utils/dom';

export function FetchLink(element: HTMLAnchorElement) {
	const STATUS_WAITING = 'waiting';
	const STATUS_UPDATING = 'updating';

	if (!(element instanceof HTMLAnchorElement)) {
		return;
	}

	let status = STATUS_WAITING;
	let controller: AbortController | undefined;

	element.addEventListener('click', loadLink);

	async function loadLink(event: Event) {
		event.preventDefault();

		if (controller && status === STATUS_UPDATING) {
			controller.abort();
		}

		status = STATUS_UPDATING;
		controller = new AbortController();

		let requestOptions: RequestInit = {
			method: 'get',
			signal: controller.signal,
		};

		let a = event.currentTarget as HTMLAnchorElement;

		let url = a.href;
		let target = url.split('#').pop();

		await fetchHtml(url, requestOptions).then((html) => {
			status = STATUS_WAITING;

			window.history.pushState(
				{
					target,
					href: window.location.href,
				},
				'',
				url,
			);

			let temp = createElement('div');
			temp.innerHTML = html;
			let r = temp.querySelector(`#${target}`);
			console.log(r);

			let el = document.getElementById(target);

			if (el) {
				el.innerHTML = r.innerHTML;
			}
		});
	}
}

let requests: {
	[target: string]: { abort: AbortSignal };
} = {};

window.addEventListener('popstate', (event) => {
	console.log(event.state);

	let { target, href } = event.state;

	if (Object.prototype.hasOwnProperty.call(requests, target)) {
		requests.target.abort();
		delete requests.target;
	}

	let controller = new AbortController();
	requests[target] = { abort: controller.signal };

	fetchHtml(href, {}, `#${target}`).then((html) => {
		let element = document.getElementById(target);
		element.innerHTML = html;
	});
});
