import { getInputNames, getInputValue, getInputValues } from '../utils/forms';
import { all } from '../utils/dom';
import { firstWhere } from '../utils/array';
import morphdom from 'morphdom';

let fieldSelector = '.js-product-variant-form-input';

export function ProductVariantSelector(element: HTMLElement) {
	element.addEventListener('change', onChange);
	element
		.querySelector('.js-variant-selector-enhanced')
		?.classList.toggle('hidden', false);
	element
		.querySelector('.js-variant-selector-fallback')
		?.classList.toggle('hidden', true);

	async function onChange() {
		let form = element.querySelector('form');
		let inputs = all(element, fieldSelector);
		let variantData = JSON.parse(element.dataset.variants || '');
		let purchasableField = form.querySelector('[name="purchasableId"]');

		let inputNames = getInputNames(inputs);
		let inputValues = getInputValues(inputNames, form);

		let match = firstWhere(variantData, inputValues);

		if (match) {
			purchasableField.value = match.id;
		} else {
			purchasableField.value = '';
		}

		let productId = getInputValue('productId', form);

		let response = await fetch(
			`/index.php?p=actions/sitemodule/product/variant&purchasableId=${purchasableField.value}&productId=${productId}`,
		);
		let text = await response.text();
		let temp = document.createElement('div');
		temp.innerHTML = text;

		let c = document.getElementById('detail');
		let n = temp.querySelector('#detail');

		morphdom(c, n);

		element
			.querySelector('.js-variant-selector-enhanced')
			?.classList.toggle('hidden', false);
		element
			.querySelector('.js-variant-selector-fallback')
			?.classList.toggle('hidden', true);
	}
}
