import { between } from '../utils/number';
import { one } from '../utils/dom';

interface Options {
	prevSelector: string;
	nextSelector: string;
	trackSelector: string;
	stepsSelector: string;
}

interface Item {
	element: HTMLElement;
	calculateRect: () => void;
	rect: { size: number; start: number; end: number };
}

const defaultOptions: Options = {
	prevSelector: '.js-slider-prev',
	nextSelector: '.js-slider-next',
	trackSelector: '.js-slider-items',
	stepsSelector: '.js-slider-steps',
};

export function Slider(
	rootElement: HTMLElement,
	options: Partial<Options> = {},
) {
	let config = { ...defaultOptions, ...options };

	let items: Item[];
	let currentItem: Item;
	let activeStep = 0;
	let steps = [];

	let scrollRoot = one(rootElement, config.trackSelector);

	if (scrollRoot === null) {
		return;
	}

	scrollRoot.addEventListener('scroll', scrollRootScroll);

	let prevControl = one(rootElement, config.prevSelector);
	let nextControl = one(rootElement, config.nextSelector);
	let paginationContainer = one(rootElement, config.stepsSelector);
	let controlAlignmentRef = one(
		rootElement,
		'.js-slider-prev-next-alignment-ref',
	);

	scrollRoot.setAttribute('tabindex', '0');
	prevControl?.addEventListener('click', () => goToRelative(-1));
	nextControl?.addEventListener('click', () => goToRelative(1));

	items = Array.from(scrollRoot.children).map((element, index) =>
		Item(element as HTMLElement, index),
	);

	window.addEventListener('DOMContentLoaded', () => {
		items.forEach((item) => item.calculateRect());
		currentItem = getClosestItem(scrollRoot.scrollLeft);
		createPagination();
		scrollRootScroll();
		positionPrevNext();
	});

	window.addEventListener('resize', () => {
		items.forEach((item) => item.calculateRect());
		createPagination();
		setActiveStep();
	});

	rootElement.addEventListener('keydown', elementKeydown);

	function getItem(value: number) {
		return items[value];
	}

	function goToRelative(amount: number) {
		let index = currentItem.index + amount;

		if (index < 0 || index > items.length - 1) {
			return;
		}

		currentItem = getItem(index);
		currentItem.scrollTo();
	}

	function getClosestItem(position: number) {
		let index = 0;
		let minDelta: number | undefined;

		for (let i = 0; i < items.length; i++) {
			let delta = Math.abs(position - items[i].rect.start);

			if (minDelta !== undefined && delta > minDelta) {
				continue;
			}

			index = i;
			minDelta = delta;
		}

		return getItem(index);
	}

	function scrollRootScroll() {
		let scrollMin = 0;
		let scrollMax = scrollRoot.scrollWidth - scrollRoot.clientWidth;

		let position = scrollRoot.scrollLeft;
		let scrollable = scrollRoot.scrollWidth > scrollRoot.clientWidth;

		rootElement.classList.toggle('is-scrollable', scrollable);
		rootElement.classList.toggle('is-not-scrollable', !scrollable);

		prevControl?.classList.toggle('hidden', !scrollable);
		nextControl?.classList.toggle('hidden', !scrollable);
		paginationContainer?.classList.toggle('hidden', !scrollable);

		prevControl?.classList.toggle('is-disabled', position <= scrollMin);
		nextControl?.classList.toggle('is-disabled', position >= scrollMax);

		currentItem = getClosestItem(position);
		setActiveStep();
	}

	function positionPrevNext() {
		if (!controlAlignmentRef) {
			return;
		}

		let rootRect = rootElement.getBoundingClientRect();
		let referenceRect = controlAlignmentRef.getBoundingClientRect();

		let top = referenceRect.top - rootRect.top + referenceRect.height / 2;

		prevControl.style.top = `${top}px`;
		nextControl.style.top = `${top}px`;
	}

	function elementKeydown(event: KeyboardEvent) {
		let { key } = event;

		switch (key) {
			case 'ArrowLeft':
				goToRelative(-1);
				event.preventDefault();
				break;
			case 'ArrowRight':
				goToRelative(1);
				event.preventDefault();
				break;
			default: // no default
		}
	}

	function createPagination() {
		if (!paginationContainer) {
			return;
		}

		let count = 0;
		let runningWidth = 0;
		let viewportWidth = scrollRoot.clientWidth;
		let referenceWidth = viewportWidth;

		for (let i = 0; i < items.length; i++) {
			let { rect } = items[i];

			runningWidth += rect.end - rect.start;

			if (runningWidth > referenceWidth) {
				count++;
				referenceWidth += viewportWidth;
			}
		}

		steps = [];
		paginationContainer.innerHTML = '';

		for (let i = 0; i <= count; i++) {
			let a = document.createElement('span');
			paginationContainer.appendChild(a);
			a.classList.add('c-slider__step');
			steps.push(a);
			a.addEventListener('click', () => {
				scrollRoot.scrollTo({
					left: i * viewportWidth,
					behavior: getScrollBehaviour(),
				});
			});
		}
	}

	function setActiveStep() {
		let t = 0;

		if (steps.length === 0) {
			return;
		}

		for (let i = 0; i < steps.length; i++) {
			let { clientWidth, scrollLeft } = scrollRoot;

			let start = i * clientWidth;
			let end = start + clientWidth;

			if (between(scrollLeft, start, end, true)) {
				t = i;
			}
		}

		steps[activeStep].classList.remove('is-active');

		activeStep = t;

		steps[activeStep].classList.add('is-active');
	}

	function getScrollBehaviour(): ScrollBehavior | undefined {
		let rootStyle = window.getComputedStyle(scrollRoot);

		if (!rootStyle.scrollBehavior) {
			return 'auto';
		}

		return rootStyle.scrollBehavior as ScrollBehavior;
	}

	function Item(element: HTMLElement, index: number) {
		let rect = { size: 0, start: 0, end: 0 };

		let item = {
			rect,
			element,
			calculateRect,
			scrollTo,
			index,
		};

		function calculateRect() {
			let { clientWidth, offsetLeft } = element;

			rect.size = clientWidth;
			rect.start = offsetLeft;
			rect.end = offsetLeft + clientWidth;
		}

		let rootStyle = window.getComputedStyle(scrollRoot);

		function scrollTo() {
			scrollRoot.scrollTo({
				top: 0,
				left: rect.start,
				behavior: getScrollBehaviour(),
			});

			currentItem = item;
			rootElement.dispatchEvent(new CustomEvent('slider:scroll-to'));
		}

		return item;
	}

	return {
		items,
		element: rootElement,
		trackElement: scrollRoot,
		getCurrentItem: () => currentItem,
	};
}
