import classifyPoint from 'robust-point-in-polygon';

interface MegaNavItem {
	isOpen: boolean;
	linkElement: HTMLElement;
	navElement: HTMLElement | null;
	open: () => void;
	close: () => void;
}

const SAMPLE_RATE = 50;

let mouseData: Coord2D = { x: 0, y: 0 };
let activeItem: MegaNavItem | undefined;

export function MegaNav() {
	let element = document.querySelector('.js-mega-nav');

	if (!(element instanceof HTMLElement)) {
		return;
	}

	Array.from(element.querySelectorAll('.js-mega-nav-link')).map((element) =>
		MegaNavItem(element as HTMLElement),
	);

	document.addEventListener('mousemove', trackMouse);
	document.addEventListener('click', handleDocumentClick);
	document.addEventListener('keydown', handleDocumentKeydown);
}

function MegaNavItem(linkElement: HTMLElement) {
	let isOpen = false;
	let targetId = linkElement.dataset.target;
	let navElement = targetId ? document.getElementById(targetId) : null;
	let timer: number | undefined;
	let exitTimer: number | undefined;

	linkElement.addEventListener('click', toggle);
	linkElement.addEventListener('keydown', handleKeydown);
	linkElement.addEventListener('mouseenter', onMouseEnter);
	linkElement.addEventListener('mouseleave', onMouseLeave);

	let item: MegaNavItem = {
		get isOpen() {
			return isOpen;
		},
		navElement,
		linkElement,
		open,
		close,
	};

	function handleKeydown(event: KeyboardEvent) {
		let { key } = event;

		switch (key) {
			case 'ArrowUp':
				close();
				event.preventDefault();
				break;
			case 'ArrowDown':
				open();
				event.preventDefault();
				break;
			case 'ArrowLeft':
				break;
			case 'ArrowRight':
				break;
			default:
		}
	}

	function onMouseEnter(event: MouseEvent) {
		let x = event.clientX;
		let y = event.clientY;

		timer = setInterval(shouldActivate, SAMPLE_RATE);

		function shouldActivate() {
			let _open = false;

			if (activeItem) {
				_open = !movingTowardMenu();
			} else {
				_open =
					Math.hypot(Math.abs(mouseData.x - x), Math.abs(mouseData.y - y)) < 5;
			}

			x = mouseData.x;
			y = mouseData.y;

			if (!_open) {
				return;
			}

			open();
			clearInterval(timer);
			exitTimer = setInterval(shouldDeactivate, SAMPLE_RATE);
		}

		function shouldDeactivate() {
			if (
				item.navElement &&
				outsideNav(item, mouseData.x, mouseData.y) &&
				!movingTowardMenu()
			) {
				close();
			} else {
				clearInterval(exitTimer);
				exitTimer = setInterval(shouldDeactivate, SAMPLE_RATE);
			}

			x = mouseData.x;
			y = mouseData.y;
		}

		function movingTowardMenu() {
			let a = x ** 2 + y ** 2;
			let b = mouseData.x ** 2 + mouseData.y ** 2;

			return a > b;
		}
	}

	function onMouseLeave() {
		clearInterval(timer);
		timer = undefined;
	}

	function toggle(event: MouseEvent) {
		if (!navElement) {
			return null;
		}

		event.preventDefault();

		return isOpen ? close() : open();
	}

	function open() {
		if (activeItem && activeItem.isOpen) {
			activeItem.close();
		}

		console.log('open');
		navElement?.classList.add('is-open');
		linkElement?.classList.add('is-open');

		isOpen = true;
		activeItem = item;
	}

	function close() {
		navElement?.classList.remove('is-open');
		linkElement?.classList.remove('is-open');

		clearInterval(timer);
		clearInterval(exitTimer);
		timer = undefined;
		exitTimer = undefined;

		isOpen = false;
		activeItem = undefined;
	}

	return item;
}

function handleDocumentClick(event: MouseEvent) {
	let target = event.target as HTMLElement;

	if (!activeItem) {
		console.log('no active item');
		return;
	}

	let { linkElement, navElement } = activeItem;

	if (
		!navElement ||
		linkElement.contains(target) ||
		navElement.contains(target)
	) {
		return;
	}

	activeItem.close();
}

function handleDocumentKeydown(event: KeyboardEvent) {
	if (event.key !== 'Escape' || !activeItem) {
		return;
	}

	activeItem.close();
}

function trackMouse(event: MouseEvent) {
	let x = event.clientX;
	let y = event.clientY;

	mouseData.x = x;
	mouseData.y = y;
}

interface Coord2D {
	x: number;
	y: number;
}

function slope(a: Coord2D, b: Coord2D) {
	return ((b.y - a.y) / (b.x - a.x)).toFixed(3);
}

function outsideNav(item: MegaNavItem, x: number, y: number) {
	let point = classifyPoint(getBounds(item), [x + 1, y + 1]);

	function getBounds(item: MegaNavItem) {
		if (!item) {
			return;
		}

		let padding = 15;

		let d = item.navElement.getBoundingClientRect();
		let t = item.linkElement.getBoundingClientRect();

		/**
		 *       C +---------+ D
		 *         | Trigger |
		 *	A +-- B +---------+ E ---------------+ F
		 *	  |                                  |
		 *	  |             Dropdown             |
		 *	  |                                  |
		 *	H +----------------------------------+ G
		 */

		return [
			/* A */ [d.left, d.top],
			/* B */ [t.left, t.bottom],
			/* C */ [t.left, t.top],
			/* D */ [t.left + t.width, t.top],
			/* E */ [t.right, t.bottom],
			/* F */ [d.left + d.width, d.top],
			/* G */ [d.left + d.width, d.top + d.height + padding],
			/* H */ [d.left, d.top + d.height + padding],
		];
	}

	return point === 1;
}
