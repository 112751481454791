export function PhoneField(element: HTMLElement) {
	let select = element.querySelector('.js-phone-field-select');
	let fauxSelect = element.querySelector('.js-phone-field-faux-select');

	select.addEventListener('change', function () {
		let selectedOption = select.options[select.selectedIndex];

		fauxSelect.textContent = `+${selectedOption.dataset.callingCode}`;
	});
}
