import { wrap } from '../utils/dom';
import showPasswordIcon from '/src/icons/show-password.svg?raw';
import hidePasswordIcon from '/src/icons/hide-password.svg?raw';

export function ShowPasswordInput(input: HTMLInputElement) {
	let passwordVisible = false;

	let form = input.form;
	let button = document.createElement('button');
	let wrapper = document.createElement('div');
	let liveRegion = document.createElement('span');

	let text = {
		hidePasswordLabelFull: 'Hide password',
		showPasswordLabelFull: 'Show password',
		passwordHiddenText: 'Your password is hidden',
		passwordVisibleText: 'Your password is visible',
	};

	wrap(input, wrapper);
	wrapper.append(button);
	wrapper.append(liveRegion);
	wrapper.classList.add('c-text-field-wrapper', 'has-button');

	liveRegion.setAttribute('aria-live', 'polite');
	liveRegion.setAttribute('class', 'visually-hidden');

	button.innerHTML = showPasswordIcon;
	button.classList.add('c-text-field-button');
	button.setAttribute('type', 'button');
	button.setAttribute('aria-controls', input.id);
	button.setAttribute('aria-label', text.showPasswordLabelFull);
	button.addEventListener('click', toggleVisibility);

	if (form) {
		form.addEventListener('submit', onFormSubmit);
	}

	function showPassword() {
		passwordVisible = true;
		input.setAttribute('type', 'text');
		button.innerHTML = hidePasswordIcon;
		button.setAttribute('aria-label', text.hidePasswordLabelFull);
		liveRegion.textContent = text.passwordVisibleText;
	}

	function hidePassword() {
		passwordVisible = false;
		input.setAttribute('type', 'password');
		button.innerHTML = showPasswordIcon;
		button.setAttribute('aria-label', text.showPasswordLabelFull);
		liveRegion.textContent = text.passwordHiddenText;
	}

	function toggleVisibility() {
		return passwordVisible ? hidePassword() : showPassword();
	}

	function onFormSubmit() {
		hidePassword();
	}
}
