import { all } from '../utils/dom';
import { isHTMLFormElement } from '../utils/types';

export function AutoSubmitForm(element: HTMLFormElement) {
	if (!isHTMLFormElement(element)) {
		return;
	}

	all(element, 'button[type="submit"]').forEach((button) => {
		button.classList.add('hidden');
	});

	element.addEventListener('change', elementChange);

	function elementChange() {
		element.submit();
	}
}
