let started = false;

let connectedComponents = [];
let registeredComponents = {};

export function App(element: HTMLElement = document.documentElement) {
	let observer = new MutationObserver((mutations) =>
		processMutations(mutations),
	);

	function start() {
		if (started) {
			return;
		}

		if (document.readyState === 'loading') {
			return document.addEventListener('DOMContentLoaded', start);
		}

		observe();
		createComponents(element);
		document.removeEventListener('DOMContentLoaded', start);
		started = true;
	}

	function processMutations(mutations: MutationRecord[]) {
		unobserve();

		mutations.forEach((record) => {
			Array.from(record.addedNodes)
				.filter(isHTMLELement)
				.forEach((element) => createComponents(element));

			Array.from(record.removedNodes)
				.filter(isHTMLELement)
				.forEach((element) => removeComponents(element));
		});

		observe();
	}

	function observe() {
		observer.observe(element, {
			childList: true,
			subtree: true,
			attributes: true,
			attributeFilter: ['data-component'],
		});
	}

	function unobserve() {
		observer.takeRecords();
		observer.disconnect();
	}

	function register(name: string, component) {
		if (isComponentRegistered(name)) {
			return;
		}

		registeredComponents[name] = component;

		if (started) {
			unobserve();
			createComponents(element, name);
			observe();
		}
	}

	function createComponents(element: HTMLElement, name: string) {
		getElements(element).map((element) =>
			getComponentNamesFromDataAttribue(element)
				.filter((n) => name === undefined || n === name)
				.forEach((name) => createComponent(element, name)),
		);
	}

	function removeComponents(element: HTMLElement, name: string) {
		getElements(element).map((element) =>
			getComponentNamesFromDataAttribue(element)
				.filter((n) => name === undefined || n === name)
				.forEach((name) => removeComponent(element, name)),
		);
	}

	function createComponent(element: HTMLElement, name: string) {
		if (!isComponentRegistered(name)) {
			return;
		}

		(getEntry(element) || createEntry(element)).add(name);
	}

	function removeComponent(element: HTMLElement, name: string) {
		let index = getEntryIndex(element);

		if (index < 0) {
			return;
		}

		connectedComponents[index].remove(name);
		connectedComponents.splice(index, 1);
	}

	function getElements(parent) {
		return [parent, ...parent.querySelectorAll('[data-component]')];
	}

	function isHTMLELement(value: any): value is HTMLElement {
		return value instanceof HTMLElement;
	}

	function getEntryIndex(element: HTMLElement) {
		return connectedComponents.findIndex((entry) => entry.element === element);
	}

	function getEntry(element: HTMLElement) {
		let index = getEntryIndex(element);

		if (index < 0) {
			return undefined;
		}

		return connectedComponents[index];
	}

	function createEntry(element: Element) {
		let entry = {
			element,
			components: [],

			get(name: string) {
				let i = this.getIndex(name);

				return i > -1 ? this.components[i] : undefined;
			},

			getIndex(name: string) {
				return this.components.findIndex((c) => c.name === name);
			},

			has(name: string) {
				return this.getIndex(name) > -1;
			},

			add(name: string) {
				if (this.has(name)) {
					return;
				}

				let component = registeredComponents[name](element);

				if (typeof component?.connect === 'function') {
					component.connect();
				}

				this.components.push({ name, component });
			},

			remove(name: string) {
				let index = this.getIndex(name);

				if (!(index > -1)) {
					return;
				}

				let component = this.components[index];

				if (typeof component?.disconnect === 'function') {
					component.disconnect();
				}

				this.components.splice(index, 1);
			},
		};

		connectedComponents.push(entry);

		return entry;
	}

	function isComponentRegistered(name: string) {
		return Object.prototype.hasOwnProperty.call(registeredComponents, name);
	}

	function getComponentNamesFromDataAttribue(element: HTMLElement) {
		if (
			!(element instanceof HTMLElement) ||
			!element.hasAttribute('data-component')
		) {
			return [];
		}

		return element.dataset.component.split(/\s+/);
	}

	return {
		start,
		register,
		connectedComponents,
		registeredComponents,
	};
}
