export function postForm(form: HTMLFormElement) {
	if (!(form instanceof HTMLFormElement)) {
		return null;
	}

	let requestOptions: RequestInit = {
		method: form.method,
		body: new FormData(form),
		headers: {
			Accept: 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
		},
	};

	return fetch('', requestOptions);
}

export async function fetchHtml(url: string, options: RequestInit | undefined) {
	let response = await fetch(url, options);

	if (!response.ok) {
		throw new Error();
	}

	return await response.text();
}
