import { isHTMLElement } from '../utils/types';

export function Disclosure(element: HTMLElement) {
	let modifierKeyPressed = false;
	let targetId = element.getAttribute('data-disclosure-target');
	let expanded = element.hasAttribute('data-disclosure-expanded');

	if (!targetId) {
		return;
	}

	let target = document.getElementById(targetId);

	if (!isHTMLElement(target)) {
		return;
	}

	toggleDisplay(expanded);
	element.setAttribute('role', 'button');
	element.setAttribute('tabindex', '0');
	element.setAttribute('aria-controls', targetId);
	element.addEventListener('click', elementClick);
	element.addEventListener('keydown', elementKeydown);

	function toggleDisplay(show: boolean) {
		(target as HTMLElement).style.display = show ? 'block' : 'none';
	}

	function elementClick() {
		expanded = !expanded;
		toggleDisplay(expanded);
	}

	function elementKeydown(event: KeyboardEvent) {
		if (!['Enter', 'Space'].includes(event.code)) {
			return;
		}

		expanded = !expanded;
		toggleDisplay(expanded);

		event.preventDefault();
	}
}
