import {
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/card-cvc';
import { createElement } from '../../../utils/dom';
import { getInputValue } from '../../../utils/forms';
import { style } from './style';

export async function StripeForm(element: HTMLFormElement) {
	const STATUS_WAITING = 'waiting';
	const STATUS_PROCESSING = 'processing';

	let form = element.closest('form');

	if (form === null) {
		return;
	}

	let status = STATUS_WAITING;
	let key = element.dataset.key;
	let namespace = element.dataset.namespace;

	let errorContainer = document.querySelector('.js-card-errors');
	let stripeStatus = element.querySelector('.js-stripe-status');

	if (!key || !namespace) {
		return;
	}

	let loadingTimer = setTimeout(() => {
		stripeStatus.classList.remove('hidden');
		stripeStatus.textContent = 'Loading...';
	}, 200);

	let { loadStripe } = await import('@stripe/stripe-js');

	let stripe = await loadStripe(key);

	if (stripe === null) {
		if (stripeStatus) {
			stripeStatus.classList.add('has-error');
			stripeStatus.classList.remove('hidden');
			stripeStatus.textContent = 'An error occurred.';
		}

		throw new Error('Failed to load Stripe');
	} else {
		clearTimeout(loadingTimer);

		stripeStatus.textContent = '';
		stripeStatus.classList.remove('hidden');
	}

	let elements = stripe.elements();

	let cardCvc = elements.create('cardCvc', { style });
	let cardExpiry = elements.create('cardExpiry', { style });
	let cardNumber = elements.create('cardNumber', { style });

	cardCvc.mount('.js-card-cvc-element');
	cardExpiry.mount('.js-card-expiry-element');
	cardNumber.mount('.js-card-number-element');

	cardCvc.on('change', onElementChange);
	cardExpiry.on('change', onElementChange);
	cardNumber.on('change', onElementChange);

	element?.classList.add('has-loaded');
	stripeStatus?.classList.add('hidden');

	function onElementChange(
		event:
			| StripeCardNumberElementChangeEvent
			| StripeCardCvcElementChangeEvent
			| StripeCardExpiryElementChangeEvent,
	) {
		displayError(event?.error?.message ?? '');
	}

	function displayError(message: string) {
		if (!errorContainer) {
			return;
		}

		if (message) {
			errorContainer.textContent = message;
			errorContainer.classList.remove('hidden');
		} else {
			errorContainer.textContent = '';
			errorContainer.classList.add('hidden');
		}
	}

	function getNamespacedInputValue(name: string) {
		return getInputValue(`${namespace}[${name}]`, form);
	}

	form.addEventListener('submit', async function postPayment(event) {
		event.preventDefault();

		if (status === STATUS_PROCESSING) {
			return;
		}

		status = STATUS_PROCESSING;

		let result = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumber,
			billing_details: {
				email: getNamespacedInputValue('email'),
				name: getNamespacedInputValue('name'),
				address: {
					line1: getNamespacedInputValue('line1'),
					city: getNamespacedInputValue('city'),
					postal_code: getNamespacedInputValue('postalCode'),
					country: getNamespacedInputValue('country'),
					state: getNamespacedInputValue('state'),
				},
			},
		});

		if (result.error) {
			status = STATUS_WAITING;

			displayError(result?.error?.message ?? '');
		} else {
			displayError('');

			let paymentMethodIdInput = createElement('input', {
				type: 'hidden',
				name: `${namespace}[paymentMethodId]`,
				value: result.paymentMethod.id,
			});

			form.appendChild(paymentMethodIdInput);
			form.submit();
		}
	});
}
