export function one(root: Document | HTMLElement = document, selector: string) {
	return root.querySelector(selector);
}

export function all(root: Document | HTMLElement = document, selector: string) {
	return Array.from(root.querySelectorAll(selector));
}

export function wrap(element: HTMLElement, wrapper: HTMLElement) {
	if (!element.parentNode) {
		return;
	}

	element.parentNode.insertBefore(wrapper, element);
	wrapper.appendChild(element);
}

export function empty(element: Element) {
	Array.from(element.childNodes).forEach((child) => child.remove());
}

export function createElement(
	tag: string,
	attributes: { [key: string]: string } = {},
) {
	let element = document.createElement(tag);

	Object.entries(attributes).forEach(([key, value]) => {
		element.setAttribute(key, value);
	});

	return element;
}
