import { empty, one } from '../../utils/dom';
import { getInputValue } from '../../utils/forms';
import { isHTMLSelectElement } from '../../utils/types';

export function Address(element: HTMLFormElement) {
	let form = element.closest('form');

	if (!form) {
		return;
	}

	let stateInput = one(form, '.js-address-state-input');
	let stateField = one(form, '.js-address-state-field');
	let countryInput = one(form, '.js-address-country-input');

	if (!isHTMLSelectElement(countryInput) || !isHTMLSelectElement(stateInput)) {
		return;
	}

	let seletedState = stateInput.dataset.initialValue;

	updateState();

	countryInput.addEventListener('change', updateState);

	stateInput.addEventListener('change', () => {
		seletedState = getInputValue(stateInput);
	});

	function updateState() {
		let countryId = getInputValue(countryInput, form);
		let matchingStates = window.states ? window.states[countryId] : null;

		empty(stateInput);

		if (
			!matchingStates ||
			(Array.isArray(matchingStates) && !matchingStates.length)
		) {
			stateField.classList.add('hidden');
			return;
		}

		stateField.classList.remove('hidden');

		let option = document.createElement('option');
		stateInput.add(option);

		Object.entries(matchingStates).forEach(([key, value]) => {
			let option = document.createElement('option');

			option.value = key;
			option.label = value;

			option.selected = seletedState === key;

			stateInput.add(option);
		});
	}
}
