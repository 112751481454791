type Limit = number | undefined | null;

export function between(
	value: number,
	lower: number,
	upper: number,
	inclusive = true,
) {
	if (inclusive) {
		return value >= lower && value <= upper;
	}

	return value > lower && value < upper;
}

export function clamp(rawValue: number, lower: Limit, upper: Limit) {
	let value = rawValue;

	if (upper !== undefined && upper !== null) {
		value = value <= upper ? value : upper;
	}

	if (lower !== undefined && lower !== null) {
		value = value >= lower ? value : lower;
	}

	return value;
}

/**
 * Wraps a number at a given value.
 *
 * @param x Value to wrap
 * @param n The maximum value
 */
export function modulo(x: number, n: number) {
	return ((x % n) + n) % n;
}
