export function isHTMLElement(value: any): value is HTMLElement {
	return value instanceof HTMLElement;
}

export function isHTMLFormElement(value: any): value is HTMLFormElement {
	return value instanceof HTMLFormElement;
}

export function isHTMLSelectElement(value: any): value is HTMLSelectElement {
	return value instanceof HTMLSelectElement;
}

export function isSubmitButton(element: any) {
	if (
		!(
			element instanceof HTMLButtonElement ||
			element instanceof HTMLInputElement
		)
	) {
		return false;
	}

	return element.type === 'submit';
}
