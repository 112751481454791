/**
 * Filter an array of objects according to a conditions object where the conditions
 * object is a key value pair. Comparisons are shallow and support comparing
 * booleans, strings, numbers, null or an array of the former. Empty strings or
 * arrays are skipped along with undefined.
 *
 * @param values
 * @param conditions
 * @returns
 */
export function where(values: Array<{}>, conditions: {}) {
	return values.filter((value) => matches(value, conditions));

	function matches(value: any, conditions: {}): boolean {
		let keys = Object.keys(conditions);

		return keys.every((key) => {
			let testValue = conditions[key];

			if (
				testValue === '' ||
				testValue === undefined ||
				isEmptyArray(testValue)
			) {
				return true;
			}

			if (typeof testValue === 'object') {
				return matches(value[key], testValue);
			}

			if (Array.isArray(testValue)) {
				return testValue.includes(value[key]);
			}

			return value[key] === testValue;
		});
	}
}

export function firstWhere(values: Array<{}>, conditions: {}) {
	return where(values, conditions)[0] || null;
}

export function isEmptyArray(value: any) {
	return Array.isArray(value) && value.length === 0;
}
