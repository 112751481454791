export function StickyNavbar() {
	let scrollTrigger: number | null;
	let rootElement = document.querySelector(
		'.js-sticky-navbar-root',
	) as HTMLElement;
	let stickyElement = document.querySelector(
		'.js-sticky-navbar-target',
	) as HTMLElement;

	if (!rootElement || !stickyElement) {
		return;
	}

	window.addEventListener('DOMContentLoaded', () => {
		calculateLayout();

		window.addEventListener('resize', calculateLayout);
		document.addEventListener('scroll', () => scroll());
	});

	function calculateLayout() {
		scrollTrigger = null;

		if (!rootElement || !stickyElement) {
			return;
		}

		rootElement.classList.remove('is-stuck');

		window.requestAnimationFrame(() => {
			let top = stickyElement.offsetTop;
			let { borderTopWidth } = window.getComputedStyle(stickyElement);

			borderTopWidth = parseInt(borderTopWidth, 10);

			rootElement.style.setProperty('--top', `${-top - borderTopWidth}px`);

			document.documentElement.style.setProperty(
				'--global-top-offset',
				`${stickyElement.getBoundingClientRect().height - borderTopWidth}px`,
			);

			scrollTrigger =
				rootElement.getBoundingClientRect().top + window.scrollY + top;
			scroll();
		});
	}

	function scroll() {
		rootElement.classList.toggle('is-stuck', window.scrollY >= scrollTrigger);
	}
}
