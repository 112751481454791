import { all, one } from '../utils/dom';
import { Slider } from './slider';

export function ProductGallery(element: HTMLElement) {
	let currentIndex = 0;

	let slider = Slider(element, {
		prevSelector: '.js-gallery-prev',
		nextSelector: '.js-gallery-next',
		trackSelector: '.js-gallery-items',
		stepsSelector: '.js-gallery-steps',
	});

	if (!slider) {
		return;
	}

	let thumbnailElement = one(element, '.js-gallery-thumbnails');
	let thumbnails = Array.from(thumbnailElement.children);

	init();

	function init() {
		for (let i = 0; i <= thumbnails.length - 1; i++) {
			thumbnails[i].setAttribute('tabindex', '0');
			thumbnails[i].setAttribute('role', 'button');

			thumbnails[i].addEventListener('click', () => select(i));

			thumbnails[i].addEventListener('keydown', (event) => {
				if (!['Enter'].includes(event.key)) {
					return;
				}

				select(i);
			});
		}

		update();

		slider.element.addEventListener('slider:scroll-to', update);
	}

	function update() {
		let newIndex = 0;

		if (slider?.getCurrentItem()) {
			newIndex = slider?.getCurrentItem().index;
		}

		slider?.items.forEach((item) => {
			all(item.element, 'iframe').forEach(pauseIFrame);
		});

		thumbnails[newIndex].classList.add('is-current');

		if (newIndex !== currentIndex) {
			thumbnails[currentIndex].classList.remove('is-current');
		}

		let top = thumbnails[newIndex].offsetTop - thumbnailElement.scrollTop;

		thumbnailElement.scrollTo({ top, behavior: 'smooth' });

		currentIndex = newIndex;
	}

	function select(index: number) {
		let { scrollBehavior } = slider.trackElement.style;

		slider.trackElement.style.scrollBehavior = 'auto';
		slider.items[index].scrollTo();
		slider.trackElement.style.scrollBehavior = scrollBehavior;
	}
}

function pauseIFrame(iframe: HTMLIFrameElement) {
	let message = '';

	if (iframe.src.startsWith('https://www.youtube')) {
		message = '{"event":"command","func":"pauseVideo","args":""}';
	}

	if (iframe.src.startsWith('https://player.vimeo.com')) {
		message = '{"method":"pause"}';
	}

	if (!message) {
		return;
	}

	iframe?.contentWindow?.postMessage(message, '*');
}
